import React, { useEffect, useMemo } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikLabel from '../../components/formik/FormikLabel';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from '../../app/reducers/Event/eventSlice';
import {
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import { options } from 'numeral';
import { XSquare } from 'react-feather';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { editScorecard } from '../../app/reducers/Scorecard/scorecardSlice';
import { createScoreBoard } from '../../app/reducers/Scoreboard/scoreboardSlice';
import { object, string, array, ref } from 'yup';

const AdjustmentModal = ({
    requestData,
    showAdjustmentModal,
    setShowAdjustmentModal,
}) => {
    const { event, elementEditData } = useSelector(getEvents);
    const { teamParticipant } = useSelector(getParticipants);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchParticipants({
                event: requestData?.event?._id,
                status: 'approved',
                sort: { collegeCode: 1 },
            })
        );
    }, [requestData]);

    const computeInitialValue = (data) => {
        if (data) {
            return {
                ...data,
                adjustmentPoints: [
                    {
                        team: '',
                        points: '',
                    },
                ],
            };
        }
        return {};
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(requestData),
        validationSchema : object({
            adjustmentPoints: array().of(
                object({
                    team: string().required('Team is require'),
                    points: string().required('Points is require'),
                })
            ),
        }),
        onSubmit: async (values) => {
            const { adjustmentPoints, ...rest } = values;
            await dispatch(editScorecard(rest));
            if (adjustmentPoints?.length > 0) {
                for (let i = 0; i < adjustmentPoints?.length; i++) {
                    const teamData = teamParticipant?.docs?.find(
                        (ele) => ele?._id === adjustmentPoints[i]?.team
                    );
                    const teamParticipantData = teamData;

                    if (teamParticipantData && teamParticipantData?.type !== 'ncp') {
                        let scoreObj = {
                            college: teamParticipantData?.college,
                            team: teamParticipantData?._id,
                            rank: 'adjustmentRank',
                            points: adjustmentPoints[i].points,
                            type: teamParticipantData?.type,
                            notes: `adjustment points`,
                            event: teamParticipantData?.event,
                            status: 'approved',
                            scorecardId: rest?._id,
                        };                        

                        await dispatch(createScoreBoard(scoreObj));
                    }
                }
            }
            setShowAdjustmentModal(false)
        },
    });
    const scoringOption = useMemo(() => {
        return teamParticipant?.docs
            ?.filter((ele) => ele.event === requestData?.event?._id)
            .map((item) => ({
                label: item.collegeCode,
                value: item._id,
            }));
    });

    return (
        <ModalBasic
            modalOpen={showAdjustmentModal}
            setModalOpen={setShowAdjustmentModal}
        >
            <form
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md "
                onSubmit={formik.handleSubmit}
            >
                {requestData?.event?.podium >= 1 && (
                    <div className="flex flex-row items-center gap-4">
                        <div className="w-1/3">
                            <FormikLabel label="1st" />
                        </div>
                        <div className="w-2/3">
                            <FormikMultiSelect
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    const duplicateValue = selectedValues.find(value => 
                                        formik.values.secondPlace.includes(value) ||
                                        formik.values.thirdPlace.includes(value) ||
                                        formik.values.noShow.includes(value) ||
                                        formik.values.disqualification.includes(value)
                                    );
                                    if (duplicateValue) {
                                        toast.error(
                                            `${scoringOption.find(opt => opt.value === duplicateValue).label} already selected`
                                        );
                                        return;
                                    }
                                    formik.setFieldValue('firstPlace', selectedValues);
                                }}
                                name="firstPlace"
                                formik={formik}
                                options={scoringOption}
                                // isDisabled
                            />
                        </div>
                    </div>
                )}
                {requestData?.event?.podium >= 2 && (
                    <div className="flex flex-row items-center gap-4">
                        <div className="w-1/3">
                            <FormikLabel label="2nd" />
                        </div>
                        <div className="w-2/3">
                            <FormikMultiSelect
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    const duplicateValue = selectedValues.find(value => 
                                        formik.values.firstPlace.includes(value) ||
                                        formik.values.thirdPlace.includes(value) ||
                                        formik.values.noShow.includes(value) ||
                                        formik.values.disqualification.includes(value)
                                    );
                                    if (duplicateValue) {
                                        toast.error(
                                            `${scoringOption.find(opt => opt.value === duplicateValue).label} already selected`
                                        );
                                        return;
                                    }
                                    formik.setFieldValue('secondPlace', selectedValues);
                                }}
                                name="secondPlace"
                                formik={formik}
                                options={scoringOption}
                                // isDisabled
                            />
                        </div>
                    </div>
                )}
                {requestData?.event?.podium >= 3 && (
                    <div className="flex flex-row items-center gap-4">
                        <div className="w-1/3">
                            <FormikLabel label="3rd" />
                        </div>
                        <div className="w-2/3">
                            <FormikMultiSelect
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    const duplicateValue = selectedValues.find(value => 
                                        formik.values.firstPlace.includes(value) ||
                                        formik.values.secondPlace.includes(value) ||
                                        formik.values.noShow.includes(value) ||
                                        formik.values.disqualification.includes(value)
                                    );
                                    if (duplicateValue) {
                                        toast.error(
                                            `${scoringOption.find(opt => opt.value === duplicateValue).label} already selected`
                                        );
                                        return;
                                    }
                                    formik.setFieldValue('thirdPlace', selectedValues);
                                }}
                                name="thirdPlace"
                                formik={formik}
                                options={scoringOption}
                                // isDisabled
                            />
                        </div>
                    </div>
                )}
                <div className="flex flex-row items-center gap-4">
                    <div className="w-1/3">
                        <FormikLabel label="No Show" />
                    </div>
                    <div className="w-2/3">
                        <FormikMultiSelect
                            onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions.map(option => option.value);
                                const duplicateValue = selectedValues.find(value => 
                                    formik.values.firstPlace.includes(value) ||
                                    formik.values.secondPlace.includes(value) ||
                                    formik.values.thirdPlace.includes(value) ||
                                    formik.values.disqualification.includes(value)
                                );
                                if (duplicateValue) {
                                    toast.error(
                                        `${scoringOption.find(opt => opt.value === duplicateValue).label} already selected`
                                    );
                                    return;
                                }
                                formik.setFieldValue('noShow', selectedValues);
                            }}
                            name="noShow"
                            formik={formik}
                            options={scoringOption}
                            // isDisabled
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <div className="w-1/3">
                        <FormikLabel label="Disqualification" />
                    </div>
                    <div className="w-2/3">
                        <FormikMultiSelect
                            onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions.map(option => option.value);
                                const duplicateValue = selectedValues.find(value => 
                                    formik.values.firstPlace.includes(value) ||
                                    formik.values.secondPlace.includes(value) ||
                                    formik.values.thirdPlace.includes(value) ||
                                    formik.values.noShow.includes(value)
                                );
                                if (duplicateValue) {
                                    toast.error(
                                        `${scoringOption.find(opt => opt.value === duplicateValue).label} already selected`
                                    );
                                    return;
                                }
                                formik.setFieldValue('disqualification', selectedValues);
                            }}
                            name="disqualification"
                            formik={formik}
                            options={scoringOption}
                            // isDisabled
                        />
                    </div>
                </div>
                <div className='mt-2'>
                    <h5 className='text-red-500'>Update Scorecard & Adjust Points</h5>
                </div>
                <div>
                    <FormikProvider value={formik} name={'adjustmentPoints'}>
                        <FieldArray
                            name="adjustmentPoints"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                                        {formik?.values?.adjustmentPoints?.map(
                                            (ele, index) => (
                                                <div
                                                    className="mb-2"
                                                    key={index}
                                                >
                                                    <div className="relative">
                                                        <XSquare
                                                            className=" right-0 text-red-500"
                                                            onClick={(e) =>{
                                                                e.stopPropagation();
                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }}
                                                        />

                                                        <div className="flex flex-row items-center gap-2 text-slate-200">
                                                            <div className="w-full ">
                                                                
                                                                    <FormikSelectGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        label='Team'
                                                                        name={`adjustmentPoints.${index}.team`}
                                                                        options={
                                                                            scoringOption
                                                                        }
                                                                        required
                                                                    />
                                                               
                                                            </div>
                                                            <div className="w-full ">
                                                                
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        label='Points'
                                                                        name={`adjustmentPoints.${index}.points`}
                                                                        required
                                                                    />
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        <div>
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                            >
                                                Add more
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                </div>
                <div>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default AdjustmentModal;
